<template>
    <div>
        <combobox-form-dialog-button
            ref="dialog"
            :items="workerIds"
            :checked-items="checkedWorkerIds"
            combobox-label="Worker IDs"
            button-label="Send Emails"
            button-icon="mdi-email"
            :can-confirm="valid"
            :progress="progress"
            @confirm="onConfirm"
            @dialog-open="onDialogOpen"
            @complete="$emit('complete')"
        >
            <template #title>
                Send Email to Workers
            </template>
            <template #body>
                <v-form v-model="valid">
                    <v-text-field dense outlined label="Subject" v-model="inputs.subject" :rules="[rules.required]" />
                    <v-textarea outlined label="Message" v-model="inputs.messageText" :rules="[rules.required]" />
                </v-form>
            </template>
            <template #alert-body="{ selectedItems }">
                <v-card-text>
                    Send emails to {{ selectedItems.length }} workers?
                </v-card-text>
            </template>
        </combobox-form-dialog-button>
    </div>
</template>
<script>
import ComboboxFormDialogButton from '../ui/ComboboxFormDialogButton'
import rules from '@/lib/input-rules'

export default {
    components: {
        ComboboxFormDialogButton,
    },
    data: () => ({
        rules,
        valid: false,
        progress: null,
        inputs: {}
    }),
    props: ['client', 'workerIds', 'checkedWorkerIds'],
    methods: {
        async onConfirm(workerIds) {
            this.progress.counts = { success: 0, error: 0, all: 1 };
            this.progress.shown = true;
            try {
                await this.client.mturk.notifyWorkers({
                    subject: this.inputs.subject,
                    message_text: this.inputs.messageText,
                    worker_ids: workerIds
                });
                this.progress.counts.success = 1;
            } catch (e) {
                this.progress.counts.error = 1;
                this.progress.errors = [e];
            } finally {
                this.progress.percentage = 100;
            }
        },
        onDialogOpen() {
            this.inputs = {
                    subject: '',
                    messageText: ''
                };
            this.progress = {
                    shown: false,
                    percentage: 0,
                    errors: [],
                    counts: {}
                };
        }
    }
}
</script>
