<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="back();">
                <v-icon v-on="on" v-bind="attrs" color="indigo">mdi-arrow-left</v-icon>
            </v-btn>
        </template>
        <span v-if="pageLabel">Back to {{ pageLabel }}</span>
    </v-tooltip>
</template>
<script>
export default {
    props: {
        pageLabel: { type: String, default: undefined }
    },
    methods: {
        back() {
            history.back();
        }
    }
}
</script>
