<template>
    <div>
        <combobox-form-dialog-button
            ref="dialog"
            :items="workerIds"
            :checked-items="checkedWorkerIds"
            combobox-label="Worker IDs"
            button-label="Associate Qualifications"
            button-icon="mdi-account-star"
            :can-confirm="valid"
            :progress="progress"
            @confirm="onConfirm"
            @dialog-open="onDialogOpen"
            @complete="$emit('complete')"
        >
            <template #title>
                Associate Qualifications with Workers
            </template>
            <template #body>
                <v-form v-model="valid">
                    <v-select
                        dense
                        outlined
                        v-model="inputs.qualificationTypeId"
                        :items="qualificationTypeItems"
                        label="QualificationTypeId"
                        :rules="[rules.required]"
                    />
                    <v-text-field
                        dense
                        outlined
                        v-model.number="inputs.integerValue"
                        type="number"
                        label="IntegerValue"
                        :rules="[rules.integer]"
                    />
                    <v-switch
                        v-model="inputs.sendNotification"
                        label="SendNotification"
                    />
                </v-form>
            </template>
            <template #alert-body="{ selectedItems }">
                <v-card-text>
                    Associate qualification to {{ selectedItems.length }} workers?
                </v-card-text>
            </template>
        </combobox-form-dialog-button>
    </div>
</template>
<script>
import ComboboxFormDialogButton from '../ui/ComboboxFormDialogButton'
import rules from '@/lib/input-rules'
import { onMTurkCredentialsUpdate } from '@/lib/mturk'

export default {
    components: {
        ComboboxFormDialogButton,
    },
    data: () => ({
        rules,
        valid: false,
        qualificationTypeItems: [],
        progress: {
            shown: false,
            percentage: 0,
            errors: [],
            counts: {}
        },
        inputs: {}
    }),
    props: ['client', 'credentials', 'workerIds', 'checkedWorkerIds'],
    methods: {
        onConfirm(workerIds) {
            try {
                this.progress.shown = true;
                this.progress.counts = {
                        success: 0,
                        error: 0,
                        all: workerIds.length,
                    },
                this.client.mturk.associateQualificationsWithWorkers.send({
                    qualification_type_id: this.inputs.qualificationTypeId,
                    worker_ids: workerIds,
                    integer_value: this.inputs.integerValue,
                    send_notification: this.inputs.sendNotification,
                });
            } catch (e) {
                this.progress.shown = false;
                this.$root.$emit('tutti-snackbar', 'error', 'Failed in associating qualifications');
                console.error(e);
            }
        },
        onDialogOpen() {
            this.inputs = {
                    qualificationTypeId: '',
                    integerValue: null,
                    sendNotification: true
                };
            this.progress.shown = false;
            this.progress.errors = [];
            onMTurkCredentialsUpdate(this, async () => {
                const qualificationTypes = await this.client.mturk.listQualificationTypes();
                this.qualificationTypeItems = qualificationTypes.map((qt) => ({
                        text: `${qt.Name} - ${qt.QualificationTypeId}`,
                        value: qt.QualificationTypeId
                    }));
            });
        }
    },
    mounted() {
        this.client.invokeOnOpen(() => {
            this.client.mturk.on('associateQualificationsWithWorkers', {
                success: (data) => {
                        if(data) {
                            this.progress.counts = data.counts;
                            this.progress.percentage = (data.counts.success+data.counts.error)/data.counts.all*100;
                            this.progress.errors = data.errors;
                        }
                    },
                error: (data) => {
                        this.progress.shown = false;
                        this.$root.$emit('tutti-snackbar', 'error', 'Failed in associating qualifications');
                        console.error(data);
                    }
            });
        });
    }
}
</script>
