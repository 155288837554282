<template>
    <tutti-dialog
        ref="dialog"
        :buttons="buttons"
        :max-width="maxWidth || 400"
    >
        <template #title>
            <slot name="title">
                <span v-html="title" />
            </slot>
        </template>
        <template #body>
            <slot name="body">
                <v-card-text v-html="body" />
            </slot>
        </template>
    </tutti-dialog>
</template>
<script>
import TuttiDialog from './TuttiDialog'
export default {
    props: [ 'maxWidth', 'title', 'body' ],
    components: {
        TuttiDialog
    },
    computed: {
        buttons() {
            return [
                {
                    label: 'Cancel',
                    attrs: {
                        color: 'grey darken-1',
                        text: true,
                    },
                    on: { click: this.cancel, }
                },
                {
                    label: 'OK',
                    attrs: {
                        color: 'indigo darken-1',
                        text: true,
                    },
                    on: { click: this.confirm, }
                }
            ]
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm');  
            this.hide();
        },
        cancel() {
            this.$emit('cancel');
            this.hide();
        },
        show() { this.$refs.dialog.show(); },
        hide() { this.$refs.dialog.hide(); }
    }
}
</script>
