<template>
    <v-row justify="center">
        <v-col cols="12">
            <page-toolbar previous-page-label="MTurk Top" title="Workers">
                <template #contents>
                    <associate-qualification-dialog-button
                        :client="client"
                        :credentials="credentials"
                        :worker-ids="workerIds"
                        :checked-worker-ids="checkedWorkerIds"
                        @complete="onComplete"
                    />
                    <send-email-dialog-button
                        :client="client"
                        :worker-ids="workerIds"
                        :checked-worker-ids="checkedWorkerIds"
                        @complete="onComplete"
                    />
                </template>
            </page-toolbar>
        </v-col>
        <v-col cols="11">
            <v-data-table
              dense
              show-select
              sort-desc
              :headers="headers"
              :items="workers"
              item-key="id"
              class="elevation-1"
              :loading="loading"
              v-model="checkedWorkers"
              sort-by="_created_at"
              :search="searchQuery"
            >
                <template v-slot:top>
                    <v-card-title>

                        <v-spacer></v-spacer>

                        <v-text-field
                            single-line
                            hide-details
                            v-model="searchQuery"
                            append-icon="mdi-magnify"
                            label="Search">
                        </v-text-field>
                    </v-card-title>
                </template>
                <!--<template v-slot:item.Projects="{ item }">
                    {{ item.Projects.join(", ") }}
                </template>-->
                <template #item._created_at="{ item }">
                    {{ standardDateTimeFormat(item._created_at) }}
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script>
import SendEmailDialogButton from './SendEmailDialogButton'
import AssociateQualificationDialogButton from './AssociateQualificationDialogButton'
import PageToolbar from '@/components/ui/PageToolbar'
import { standardDateTimeFormat } from '@/lib/utils'
import { onMTurkCredentialsUpdate } from '@/lib/mturk'

export default {
    components: {
        PageToolbar,
        SendEmailDialogButton,
        AssociateQualificationDialogButton,
    },
    data: () => ({
        loading: false,

        searchQuery: '',
        headers: [
          { text: 'Worker ID', value: 'id' },
          { text: 'Worker ID (MTurk)', value: 'platform_worker_id' },
          //{ text: 'Visited Projects', value: 'Projects' },
          { text: 'Created Time', value: '_created_at' },
        ],
        checkedWorkers: [],
        workers: [],
    }),
    props: ['client', 'credentials'],

    computed: {
        checkedWorkerIds() {
            return this.checkedWorkers.map((worker) => (worker.platform_worker_id));
        },
        workerIds() {
            return this.workers.map((worker) => (worker.platform_worker_id));
        },
    },
    methods: {
        standardDateTimeFormat,
        async listWorkers() {
            this.loading = true;
            this.workers = await this.client.mturk.listWorkers();
            this.loading = false;
        },
        onComplete() {
            this.checkedWorkers = [];
        }
    },
    mounted() {
        onMTurkCredentialsUpdate(this, async () => {
            this.listWorkers();
        });
    }
}
</script>
<style>
.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    background-color: #f5f5f5;
}
.is-root, .is-root div {
    font-size: 9pt;
}
</style>
